import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import './D3WordTree.css';
import tippy, { hideAll } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { FaExpand, FaCompress } from 'react-icons/fa';

const D3WordTree = ({ data, searchTerm }) => {
  const svgRef = useRef();
  const containerRef = useRef();
  const buttonRef = useRef();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const currentTransform = useRef(null); // Track the current zoom transform
  const initialTransform = useRef(null); // Store the initial centering transform
  const margin = { top: 20, right: 150, bottom: 20, left: 150 };

  const preprocessData = (data, searchTerm) => {
    if (!Array.isArray(data)) {
      return { name: searchTerm, children: [] };
    }

    const root = {
      name: searchTerm,
      children: [],
    };

    const normalize = str => str.replace(/[.,/#!$%^&*;:{}=\-_`~()"]/g, "").toLowerCase();
    const normalizedSearchTerm = normalize(searchTerm);

    const groupedData = data.reduce((acc, verse) => {
      const originalWords = verse.text.split(/\s+/);
      const normalizedText = normalize(verse.text);
      const words = normalizedText.split(/\s+/);
      const searchTermWords = normalizedSearchTerm.split(' ');

      const searchTermIndex = words.findIndex((word, index) =>
        searchTermWords.every((term, termIndex) =>
          words[index + termIndex] && words[index + termIndex] === term
        )
      );

      if (searchTermIndex === -1) return acc;

      const before = searchTermIndex > 0 ? originalWords.slice(searchTermIndex - 1, searchTermIndex).join(' ') : '';
      const after = searchTermIndex < words.length - searchTermWords.length ? originalWords.slice(searchTermIndex + searchTermWords.length, searchTermIndex + searchTermWords.length + 1).join(' ') : '';
      const phrase = `${before} ${originalWords.slice(searchTermIndex, searchTermIndex + searchTermWords.length).join(' ')} ${after}`.trim();

      if (!acc[phrase]) {
        acc[phrase] = [];
      }
      acc[phrase].push(verse);
      return acc;
    }, {});

    for (const [key, value] of Object.entries(groupedData)) {
      if (value.length > 1) {
        root.children.push({
          name: key,
          children: value.map(verse => ({
            name: verse.text,
            reference: verse.reference,
          })),
          occurrences: value.length
        });
      } else {
        value.forEach(verse => {
          root.children.push({
            name: verse.text,
            reference: verse.reference,
          });
        });
      }
    }

    root.occurrences = data.length;

    return root;
  };

  useEffect(() => {
    const margin = { top: 20, right: 150, bottom: 20, left: 150 };

    const rootData = preprocessData(data, searchTerm);
    const nodeCount = rootData.children ? rootData.children.length : 0; // Safely access children

    const height = Math.max(500, nodeCount * 20);
    const width = Math.min(1200, Math.max(700, nodeCount * 15)) - margin.left - margin.right;

    const svgHeight = isFullScreen ? window.innerHeight - 40 : height;
    const svgWidth = isFullScreen ? window.innerWidth : width + margin.left + margin.right;

    const zoomScaleExtent = [0.5, Math.max(2, nodeCount / 10)];

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const g = svg
      .attr('width', '100%')
      .attr('height', svgHeight)
      .attr('viewBox', `0 0 ${svgWidth} ${svgHeight + margin.top + margin.bottom}`)
      .style('border', '1px solid var(--divider-color)')
      .append('g');

    const zoom = d3.zoom()
      .scaleExtent(zoomScaleExtent)
      .on('zoom', event => {
        g.attr('transform', event.transform);
        currentTransform.current = event.transform; // Store the current transform
        hideAll(); // Hide all tooltips on zoom
      });

    svg.call(zoom);

    if (!initialTransform.current) {
      const centerX = svgWidth / 2;
      const centerY = svgHeight / 2;
      initialTransform.current = d3.zoomIdentity.translate(centerX - margin.left, centerY);
      svg.call(zoom.transform, initialTransform.current);
      currentTransform.current = initialTransform.current; // Set the initial transform as current
    } else if (currentTransform.current) {
      svg.call(zoom.transform, currentTransform.current);
    }

    const treeLayout = d3.tree()
      .size([svgHeight - margin.top - margin.bottom, width])
      .nodeSize([30, 200])
      .separation((a, b) => a.parent === b.parent ? 1 : 1.5);

    const root = d3.hierarchy(rootData);

    root.sort((a, b) => {
      if (a.children && !b.children) return -1;
      if (!a.children && b.children) return 1;
      return b.data.occurrences - a.data.occurrences;
    });

    treeLayout(root);

    g.selectAll('.link')
      .data(root.descendants().slice(1))
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('d', d => `
        M${d.y},${d.x}
        C${(d.y + d.parent.y) / 2},${d.x}
         ${(d.y + d.parent.y) / 2},${d.parent.x}
         ${d.parent.y},${d.parent.x}
      `);

    const node = g.selectAll('.node')
      .data(root.descendants())
      .enter()
      .append('g')
      .attr('class', d => `node ${d.children ? 'node--internal' : 'node--leaf'}`)
      .attr('transform', d => `translate(${d.y},${d.x})`)
      .each(function(d) {
        const nodeElement = this;
        let tooltipContent;

        if (d.depth === 0 || d.children) {
          tooltipContent = `<strong>${d.data.name}</strong><br>${d.data.occurrences} verses`;
        } else {
          const parentNode = d.parent;
          const internalPhrase = parentNode.data.name;
          const regex = new RegExp(`(${internalPhrase})`, 'gi');
          const highlightedText = d.data.name.replace(regex, '<span class="highlighted-text">$1</span>');
          tooltipContent = `<strong>${d.data.reference || ''}</strong><br>${highlightedText}`;
        }

        tippy(nodeElement, {
          content: tooltipContent,
          allowHTML: true,
          placement: 'top',
          arrow: true,
          trigger: 'manual',
          theme: 'custom-tooltip',
        });

        d3.select(nodeElement).on('click', function() {
          const instance = nodeElement._tippy;
          if (instance) {
            instance.show();
          }
        });
      });

    node.append('circle')
      .attr('r', 10);

    node.append('text')
      .attr('dy', '.35em')
      .attr('x', d => (d.children ? -13 : 16))
      .style('text-anchor', d => (d.children ? 'end' : 'start'))
      .style('font-weight', d => (d.children || d.depth === 0 ? 'bold' : 'normal'))
      .style('font-size', d => (d.depth === 0 ? '20px' : '16px'))
      .text(d => d.children ? d.data.name : d.data.reference);

  }, [data, searchTerm, isFullScreen]);

  useEffect(() => {
    const svgElement = svgRef.current;

    const handleInteraction = () => {
      hideAll();
    };

    svgElement.addEventListener('mousedown', handleInteraction);
    svgElement.addEventListener('touchstart', handleInteraction);

    return () => {
      svgElement.removeEventListener('mousedown', handleInteraction);
      svgElement.removeEventListener('touchstart', handleInteraction);
    };
  }, []);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  
    const svg = d3.select(svgRef.current);
  
    if (!isFullScreen) {
      // Entering fullscreen - prevent body scroll
      document.body.style.overflow = 'hidden';
  
      // Apply current zoom when entering fullscreen
      if (currentTransform.current) {
        svg.call(d3.zoom().transform, currentTransform.current);
      }
    } else {
      // Exiting fullscreen - restore body scroll
      document.body.style.overflow = 'scroll';
  
      // Apply the same zoom state when exiting fullscreen
      if (currentTransform.current) {
        svg.call(d3.zoom().transform, currentTransform.current);
      }
    }
  };  
  
  useEffect(() => {
    const svg = d3.select(svgRef.current);
    
    if (!initialTransform.current) {
      const svgWidth = svgRef.current.clientWidth;
      const svgHeight = svgRef.current.clientHeight;
      const centerX = svgWidth / 2 - margin.left;
      const centerY = svgHeight / 2 - margin.top;
      
      initialTransform.current = d3.zoomIdentity.translate(centerX, centerY);
      svg.call(d3.zoom().transform, initialTransform.current);
      currentTransform.current = initialTransform.current; // Set the initial transform as the current
    }
  }, []);
  
  return (
    <div ref={containerRef} className={`d3-container ${isFullScreen ? 'fullscreen' : ''}`}>
      <button ref={buttonRef} onClick={toggleFullScreen} className="fullscreen-btn">
        {isFullScreen ? <FaCompress /> : <FaExpand />}
      </button>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default D3WordTree;