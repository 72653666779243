import React, { useRef, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './BibleBarChart.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BibleBarChart = ({ data, title, bookName, onBarClick, bookOccurrences, bookVerses }) => {
  const [lastClickedBar, setLastClickedBar] = useState(null);
  const touchTimeout = useRef(null);
  const longPressDuration = 500; // Duration for a long press (in milliseconds)
  const chartInstanceRef = useRef(null);

  // Use bookName as the label if drilling down to a specific book, otherwise use titles of multiple books
  const labels = bookName ? [bookName] : Object.keys(data);

  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: `"${title}"`,
        data: bookName ? [data[bookName] || 0] : Object.values(data),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'false',
        position: 'top',
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            const book = context[0].label;
            return book;
          },
          label: function (context) {
            const book = context.label;
            const occurrences = bookOccurrences[book] || 0;
            const verses = bookVerses[book] || 0;
            const occurrenceText = occurrences === 1 ? 'occurrence' : 'occurrences';
            const verseText = verses === 1 ? 'verse' : 'verses';
            return [
              `"${title}"`,
              `${occurrences} ${occurrenceText}`,
              `${verses} ${verseText}`,
            ];
          },
        },
        position: 'nearest',
        align: 'center',
        yAlign: 'center',
      },
    },
    layout: {
      padding: {
        top: -5,
        bottom: Object.keys(data).length > 1 ? -25 : 0,
      },
    },
    scales: {
      x: {
        title: {
          display: Object.keys(data).length > 1,
          text: bookName || '', 
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            return Number.isInteger(value) ? value : '';
          },
          stepSize: 1,
        },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const chartElement = elements[0];
        const book = chartData.labels[chartElement.index];
        if (lastClickedBar === book) {
          onBarClick(book);
        } else {
          setLastClickedBar(book);
          setTimeout(() => setLastClickedBar(null), 2000); // Reset last clicked bar after 2 seconds
        }
      }
    },
    
  };

  useEffect(() => {
    const handleTouchStart = (event) => {
      if (touchTimeout.current) {
        clearTimeout(touchTimeout.current);
      }
      touchTimeout.current = setTimeout(() => {
        if (chartInstanceRef.current) {
          const elements = chartInstanceRef.current.getElementsAtEventForMode(event, 'nearest', { intersect: true });
          if (elements.length > 0) {
            const chartElement = elements[0];
            const book = chartData.labels[chartElement.index];
            onBarClick(book);
          }
        }
      }, longPressDuration);
    };

    const handleTouchEnd = () => {
      if (touchTimeout.current) {
        clearTimeout(touchTimeout.current);
      }
    };

    if (chartInstanceRef.current) {
      const canvas = chartInstanceRef.current.canvas;
      canvas.addEventListener('touchstart', handleTouchStart);
      canvas.addEventListener('touchend', handleTouchEnd);
      return () => {
        canvas.removeEventListener('touchstart', handleTouchStart);
        canvas.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, [chartData.labels, onBarClick, longPressDuration]);

  return (
    <div className="chart-container">
      <Bar
        data={chartData}
        options={options}
        ref={(chartInstance) => {
          if (chartInstance) {
            chartInstanceRef.current = chartInstance;
          }
        }}
      />
    </div>
  );
};

export default BibleBarChart;
