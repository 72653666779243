// searchUtils.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '/api';

export const fetchAutocompleteSuggestions = async (query) => {
  try {
    const response = await axios.get(`${API_URL}/autocomplete?query=${query}`);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const fetchSearchResults = async (query) => {
  try {
    const response = await axios.get(`${API_URL}/search?query=${query}`);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const fetchBookSearchResults = async (bookName, searchTerm) => {
  try {
    const response = await axios.get(`${API_URL}/search/book?bookName=${encodeURIComponent(bookName)}&searchTerm=${encodeURIComponent(searchTerm)}`);
    return response.data;
  } catch (error) {
    return { error: 'Error fetching book search results' };
  }
};

export const fetchAvailableChapters = async (bookName) => {
  try {
    const response = await axios.get(`${API_URL}/chapters?bookName=${bookName}`);
    return response.data;
  } catch (error) {
    return [];
  }
};
