// SearchBar.js

import React, { useState, useRef } from 'react';
import { AutoComplete, Input, List, Button, Tabs } from 'antd';
import { fetchAutocompleteSuggestions, fetchSearchResults, fetchBookSearchResults, fetchAvailableChapters } from '../utils/searchUtils';
import './SearchBar.css';
import BibleBarChart from './BibleBarChart';
import D3WordTree from './D3WordTree';
import ValidBooks from './ValidBooks';
import { ArrowLeftOutlined } from '@ant-design/icons';

const SearchBar = ({ setShowLogo }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [results, setResults] = useState([]);
  const [showBackButton, setShowBackButton] = useState(false);
  const [summary, setSummary] = useState({ word: '', text: '' });
  const [availableChapters, setAvailableChapters] = useState([]);
  const [bookName, setBookName] = useState('');
  const [invalidChapter, setInvalidChapter] = useState(false);
  const [chartData, setChartData] = useState({});
  const [treeData, setTreeData] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [isPassage, setIsPassage] = useState(false);
  const [validChapterSelected, setValidChapterSelected] = useState(false);
  const [bookOccurrences, setBookOccurrences] = useState({});
  const [bookVerses, setBookVerses] = useState({});
  const [disableLogo, setDisableLogo] = useState(false);
  
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const validBooks = ValidBooks();

  const handleFocus = () => {
    setDisableLogo(true);
    setShowLogo(false);
    setShowBackButton(true);
  
    // Execute only if screen width is less than 600px (mobile view)
    if (window.innerWidth < 600) {
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
  
        // Simulate rotation by changing the viewport width briefly
        document.documentElement.style.width = "99%"; // Small width adjustment
        setTimeout(() => {
          document.documentElement.style.width = ""; // Reset width to normal
          if (inputRef.current) {
            inputRef.current.focus({ preventScroll: true });
          }
        }, 100); // Delay to allow the browser to process the width change
      }, 50);
    }
  };  

  const handleClear = () => {
    setSearchTerm('');
    setSuggestions([]);
    setResults([]);
    setSummary({ word: '', text: '' });
    setChartData({});
    setTreeData([]);
    setAvailableChapters([]);
    setActiveTab('1');
    setInvalidChapter(false);
  };

  const isPassageReference = (value) => {
    const passagePattern = /^[1-3]?[ ]?[A-Za-z]+[ ]?[0-9]+(:[0-9]+([, -][0-9]+)*)?$/;
    const chapterPattern = /^[1-3]?[A-Za-z]+[ ]?[0-9]+$/;
    return passagePattern.test(value) || chapterPattern.test(value);
  };
  
  const preprocessInput = (value) => {
    value = value.replace(/(\d)([A-Za-z]+)/g, '$1 $2');
    value = value.replace(/([a-zA-Z])([0-9])/g, '$1 $2');
    value = value.replace(/,\s+/g, ',').replace(/:\s+/g, ':');
    return value;
  };

  const handleBackButtonClick = () => {
    setShowLogo(true);
    setShowBackButton(false);
    setDisableLogo(false);
    setSearchTerm('');
    setSuggestions([]);
    setResults([]);
    setSummary({ word: '', text: '' });
    setChartData({});
    setTreeData([]);
    setAvailableChapters([]);
    setActiveTab('1');
    setInvalidChapter(false); 
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };  

  const handleSearchChange = async (value) => {
    const processedValue = preprocessInput(value);
    setSearchTerm(processedValue);
  
    if (processedValue === '') {
      handleClear();
      if (!disableLogo) {
        setShowLogo(true);
      }
      return;
    }
  
    setIsPassage(isPassageReference(processedValue));
    setValidChapterSelected(false);

    // Clear results, summary, chart data, and tree data when the search term is modified
    setResults([]);
    setSummary({ word: '', text: '' });
    setChartData({});
    setTreeData([]);
    setAvailableChapters([]);

    if (activeTab === '2') {
      setActiveTab('1');
    }

    if (processedValue.length > 0) {
      const suggestions = await fetchAutocompleteSuggestions(processedValue);
      const formattedSuggestions = suggestions
        .filter(s => s.count > 0)
        .map(s => ({
          value: `${s.suggestion} (${s.count})`,
          suggestion: s.suggestion,
          count: s.count,
          totalOccurrences: s.totalOccurrences
        }))
        .sort((a, b) => b.count - a.count); // Sort by count in descending order

      if (formattedSuggestions.length === 0 && !isPassageReference(processedValue)) {
        setSuggestions([{ value: 'No suggestions found', suggestion: 'No suggestions found', count: 0 }]);
      } else {
        setSuggestions(formattedSuggestions);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSearch = async (value) => {
    if (inputRef.current) {
      inputRef.current.blur(); // Dismiss the keyboard
    }

    const processedValue = preprocessInput(value);
    if (processedValue.length > 0) {
      try {
        if (processedValue.match(/^\d\s?[A-Za-z]+$/)) {
          const chapters = await fetchAvailableChapters(processedValue);
          setInvalidChapter(true);
          setAvailableChapters(chapters);
          setBookName(processedValue);
          setResults([]);
          setSummary({
            word: '',
            text: 'Please select a valid chapter below:'
          });
          setActiveTab('1'); // Ensure we stay on the search results tab
        } else {
          const resultsData = await fetchSearchResults(processedValue);

          if (resultsData.error && resultsData.error === 'Invalid chapter') {
            setInvalidChapter(true);
            setAvailableChapters(resultsData.availableChapters);
            setBookName(resultsData.book);
            setResults([]);
            setSummary({
              word: '',
              text: 'Please select a valid chapter below:'
            });
            setActiveTab('1'); // Ensure we stay on the search results tab
          } else if (resultsData.error && resultsData.error === 'Invalid book') {
            setInvalidChapter(false);
            setResults([]);
            setSummary({
              word: '',
              text: 'No suggestions found'
            });
            setActiveTab('1'); // Ensure we stay on the search results tab
          } else if (resultsData.passages.length === 0) {
            setInvalidChapter(false);
            setAvailableChapters([]);
            setResults([]);
            setSummary({
              word: `"${processedValue}"`,
              text: 'No suggestions found'
            });
            setChartData({});
            setTreeData([]);
            setActiveTab('1'); // Ensure we stay on the search results tab
          } else {
            setInvalidChapter(false);
            setAvailableChapters([]);
            const totalOccurrences = resultsData.totalOccurrences || 0;
            const verseCount = resultsData.verseCount || 0;
            const summaryText = isPassageReference(processedValue)
              ? processedValue.includes(':')
                ? `Showing "${processedValue}"`
                : `Showing ${resultsData.book || ''} ${resultsData.chapter || ''}:1-${resultsData.chapterTotalVerses || ''}`
              : `occurs ${totalOccurrences} ${totalOccurrences === 1 ? 'time' : 'times'} in ${verseCount} ${verseCount === 1 ? 'verse' : 'verses'}.`;
  
            setResults(resultsData.passages && resultsData.passages.length > 0 ? resultsData.passages : []);
            setSummary({
              word: `"${processedValue}"`,
              text: summaryText
            });

            // Update chart data
            const occurrencesMap = {};
            const versesMap = {};
            resultsData.passages.forEach(passage => {
              // Extract the book name by removing the verse and chapter from the reference string
              const reference = passage.reference;
              const book = reference.split(/[0-9]/)[0].trim(); // Get everything before the chapter number
              if (validBooks.includes(book)) {
                const occurrenceCount = (passage.text.match(new RegExp(`\\b${processedValue}\\b`, 'gi')) || []).length;
                occurrencesMap[book] = (occurrencesMap[book] || 0) + occurrenceCount;
                versesMap[book] = (versesMap[book] || 0) + 1;
              }
            });
            setChartData(versesMap);
            setBookOccurrences(occurrencesMap);
            setBookVerses(versesMap);
  
            // Update tree data
            setTreeData(resultsData.passages);
            setActiveTab('1'); // Ensure we stay on the search results tab
          }
        }
      } catch (error) {
        // Try to handle no results found more gracefully
        const closestSuggestion = suggestions.length > 0 && suggestions[0].suggestion !== 'No suggestions found' ? suggestions[0].suggestion : '';
        if (!closestSuggestion) {
          setResults([]);
          setSummary({
            word: `"${processedValue}"`,
            text: 'No suggestions found'
          });
          setChartData({});
          setTreeData([]);
          setActiveTab('1');
        } else {
          const fallbackSearchTerm = closestSuggestion || processedValue.charAt(0).toUpperCase() + processedValue.slice(1);
          const resultsData = await fetchSearchResults(fallbackSearchTerm);
          const totalOccurrences = resultsData.totalOccurrences || 0;
          const verseCount = resultsData.verseCount || 0;
          const summaryText = `occurs ${totalOccurrences} ${totalOccurrences === 1 ? 'time' : 'times'} in ${verseCount} ${verseCount === 1 ? 'verse' : 'verses'}.\nNo exact results found for "${processedValue}"\nShowing results for "${fallbackSearchTerm}"`;
  
          setResults(resultsData.passages || []);
          setSummary({
            word: `"${fallbackSearchTerm}"`,
            text: summaryText
          });

          // Update chart data
          const occurrencesMap = {};
          const versesMap = {};
          resultsData.passages.forEach(passage => {
            const reference = passage.reference;
            const book = reference.split(/[0-9]/)[0].trim(); // Get everything before the chapter number
            if (validBooks.includes(book)) {
              const occurrenceCount = (passage.text.match(new RegExp(`\\b${fallbackSearchTerm}\\b`, 'gi')) || []).length;
              occurrencesMap[book] = (occurrencesMap[book] || 0) + occurrenceCount;
              versesMap[book] = (versesMap[book] || 0) + 1;
            }
          });

          setChartData(versesMap);
          setBookOccurrences(occurrencesMap);
          setBookVerses(versesMap);
  
          // Update tree data
          setTreeData(resultsData.passages);
          setActiveTab('1'); // Ensure we stay on the search results tab

          // Update search term to fallback search term
          setSearchTerm(fallbackSearchTerm);
        }
      }
    } else {
      setResults([]);
      setSummary({ word: '', text: '' });
      setTreeData([]);
      setActiveTab('1'); // Ensure we stay on the search results tab
    }

    // Clear suggestions after search
    setSuggestions([]);
  };

  const handleSelect = async (value) => {
    // Dismiss the keyboard on mobile
    if (inputRef.current) {
      inputRef.current.blur(); // Dismiss the keyboard
    }
    const processedValue = preprocessInput(value);
    const selectedSuggestion = suggestions.find(s => s.value === processedValue);
    if (!selectedSuggestion || selectedSuggestion.suggestion === 'No suggestions found') {
      setResults([]);
      setSummary({
        word: `"${processedValue}"`,
        text: 'No suggestions found'
      });
      setChartData({});
      setTreeData([]);
      setActiveTab('1');
      return;
    }
    setSearchTerm(selectedSuggestion.suggestion);
    const resultsData = await fetchSearchResults(selectedSuggestion.suggestion);
    const totalOccurrences = resultsData.totalOccurrences || 0;
    const verseCount = resultsData.verseCount || 0;
    const summaryText = isPassageReference(selectedSuggestion.suggestion)
      ? `Showing "${selectedSuggestion.suggestion}"`
      : `occurs ${totalOccurrences} ${totalOccurrences === 1 ? 'time' : 'times'} in ${verseCount} ${verseCount === 1 ? 'verse' : 'verses'}.`;
    setResults(resultsData.passages || []);
    setSummary({
      word: `"${selectedSuggestion.suggestion}"`,
      text: summaryText
    });

    // Update chart data
    const occurrences = resultsData.passages.reduce((acc, passage) => {
      const referenceParts = passage.reference.split(' ');
      let book = referenceParts[0];
      if (referenceParts[0].match(/^\d$/) && referenceParts[1]) {
        book = `${referenceParts[0]} ${referenceParts[1]}`;
      }
      if (validBooks.includes(book)) {
        acc[book] = (acc[book] || 0) + 1;
      }
      return acc;
    }, {});
    setChartData(occurrences);

    // Update book occurrences and verses
    const occurrencesMap = {};
    const versesMap = {};
    resultsData.passages.forEach(passage => {
      const referenceParts = passage.reference.split(' ');
      let book = referenceParts[0];
      if (referenceParts[0].match(/^\d$/) && referenceParts[1]) {
        book = `${referenceParts[0]} ${referenceParts[1]}`;
      }
      if (validBooks.includes(book)) {
        occurrencesMap[book] = (occurrencesMap[book] || 0) + (passage.occurrences || 1);
        versesMap[book] = (versesMap[book] || 0) + 1;
      }
    });
    setBookOccurrences(occurrencesMap);
    setBookVerses(versesMap);

    // Update tree data
    setTreeData(resultsData.passages);
    setActiveTab('1'); // Ensure we stay on the search results tab
  };

  const handleBarClick = async (book) => {
  
    // Check if the chart is displaying results for only one book
    if (Object.keys(chartData).length === 1 && chartData[book]) {
      // If yes, perform a full search for the current search term
      await handleSearch(searchTerm);
      return;
    }
  
    // Otherwise, perform a book-specific search
    const resultsData = await fetchBookSearchResults(book, searchTerm);
  
    if (resultsData.error) {
      setResults([]);
      setSummary({
        word: `"${book}"`,
        text: 'No results found'
      });
      setChartData({});
      setTreeData([]);
      setActiveTab('1');  // Ensure we stay on the search results tab
      return;
    }
  
    // Calculate the correct total occurrences manually
    const calculatedTotalOccurrences = resultsData.passages.reduce((acc, passage) => {
      const count = (passage.text.match(new RegExp(`\\b${searchTerm}\\b`, 'gi')) || []).length;
      return acc + count;
    }, 0);
    const verseCount = resultsData.passages.length || 0;
    const summaryText = `occurs ${calculatedTotalOccurrences} ${calculatedTotalOccurrences === 1 ? 'time' : 'times'} in ${verseCount} ${verseCount === 1 ? 'verse' : 'verses'}, in ${book}.`;
    setResults(resultsData.passages || []);
    setSummary({
      word: `"${searchTerm}"`,
      text: summaryText
    });
  
    // Update chart data
    const occurrencesMap = {};
    const versesMap = {};
    resultsData.passages.forEach(passage => {
      const reference = passage.reference;
      const bookName = reference.split(/[0-9]/)[0].trim();
      if (validBooks.includes(bookName)) {
        const occurrenceCount = (passage.text.match(new RegExp(`\\b${searchTerm}\\b`, 'gi')) || []).length;
        occurrencesMap[bookName] = (occurrencesMap[bookName] || 0) + occurrenceCount;
        versesMap[bookName] = (versesMap[bookName] || 0) + 1;
      }
    });
    setChartData(versesMap);
    setBookOccurrences(occurrencesMap);
    setBookVerses(versesMap);
  
    // Update tree data
    setTreeData(resultsData.passages);
    setActiveTab('1');  // Ensure we stay on the search results tab
  };

  const handleChapterClick = async (chapter) => {
    const newSearchTerm = `${bookName} ${chapter}`;
    setInvalidChapter(false);
    setValidChapterSelected(true);
    setSearchTerm(newSearchTerm);
    await handleSearch(newSearchTerm);
    setActiveTab('1'); // Ensure we stay on the search results tab
  };

  const highlightSearchTerm = (text, term) => {
    const regex = new RegExp(`(${term})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? <span key={index} className="highlight">{part}</span> : part
    );
  };

  const tabItems = [
    {
      key: '1',
      label: searchTerm ? `Search Results for "${searchTerm}"` : 'Search Results',
      children: (
        <>
          {summary.word && !invalidChapter && (
            <div className="search-summary">
              <div className="summary-word">{summary.word}</div>
              <div className="summary-text">{summary.text}</div>
            </div>
          )}
          {invalidChapter && (
            <div className="search-summary">
              <div className="summary-text">{summary.text}</div>
            </div>
          )}
          {availableChapters.length > 0 && (
            <div className="chapter-list">
              {availableChapters.map((chapter) => (
                <Button key={chapter} onClick={() => handleChapterClick(chapter)}>
                  {chapter}
                </Button>
              ))}
            </div>
          )}
          {results.length > 0 && (
            <List
              bordered
              dataSource={results}
              renderItem={(item) => (
                <List.Item key={item.reference} className="List-item">
                  <div className="reference">{item.reference}</div>
                  <div className="divider"></div>
                  <div className="verse-content">{highlightSearchTerm(item.text, searchTerm)}</div>
                </List.Item>
              )}
              style={{ marginTop: '20px' }}
            />
          )}
          {results.length === 0 && (
            <div className="search-summary">
              <div className="summary-text">No results found</div>
            </div>
          )}
        </>
      ),
    },
    {
      key: '3',
      label: 'Chart',
      children: (
        <BibleBarChart 
          data={chartData} 
          title={searchTerm} 
          onBarClick={handleBarClick}
          bookOccurrences={bookOccurrences}
          bookVerses={bookVerses}
        />
      ),
      disabled: Object.keys(chartData).length === 0, // Disable tab if no chart data
    }
  ];
  
  // Conditionally add the Tree tab if the conditions are met
  if (!isPassage && results.length > 0 && !invalidChapter && !validChapterSelected) {
    tabItems.push({
      key: '2',
      label: 'Tree',
      children: (
        <D3WordTree data={treeData} searchTerm={searchTerm} theme="dark" />
      ),
      disabled: results.length === 0,
    });
  }
  
  return (
    <div className="search-container" ref={containerRef}>
      <div className="search-bar-container">
        {showBackButton && (
          <button className="back-button" onClick={handleBackButtonClick}>
            <ArrowLeftOutlined />
          </button>
        )}
        <AutoComplete
          value={searchTerm}
          onChange={handleSearchChange}
          onSelect={handleSelect}
          options={suggestions}
          className="search-bar"
        >
          <Input.Search
            placeholder="Search the Bible"
            enterButton
            size="large"
            allowClear
            enterKeyHint="search"
            onSearch={handleSearch}
            onFocus={handleFocus}       
            autoCapitalize="none"
            autoCorrect="on" 
            spellCheck="true"
            ref={inputRef}
          />
        </AutoComplete>
      </div>
      {(Object.keys(chartData).length > 0 || results.length > 0 || availableChapters.length > 0) && (
        <Tabs activeKey={activeTab} onChange={setActiveTab} className="tabs-container" items={tabItems} />
      )}
    </div>
  );  
};

export default SearchBar;
