// FooterAd.js

import React, { useEffect, useState } from 'react';
import './FooterAd.css';

const FooterAd = ({ client, slot }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (window.adsbygoogle && window.adsbygoogle.length > 0) {
      window.adsbygoogle.push({});
    }
  }, []);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className={`footer-ad ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="toggle-handle" onClick={toggleVisibility}>
        <svg
          style={{
            margin: "0px",
            position: "absolute",
            bottom: "0px",
            left: "0%",
            display: "block",
            width: "80px",
            height: "30px",
            transform: "none",
            pointerEvents: "initial"
          }}
          viewBox="0 0 80 30"
        >
          <defs>
            <filter id="dropShadowTop" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feComponentTransfer in="SourceAlpha" result="TransferredAlpha">
                <feFuncR type="discrete" tableValues="0.5" />
                <feFuncG type="discrete" tableValues="0.5" />
                <feFuncB type="discrete" tableValues="0.5" />
              </feComponentTransfer>
              <feGaussianBlur in="TransferredAlpha" stdDeviation="2" />
              <feOffset dx="0" dy="0" result="offsetblur" />
              <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <path
            d="M0,26 L0,6 A6,6 0 0,1 6,1 L50,1 A6,6 0 0,1 56,6 L56,20 A6,6 0 0,0 62,26 Z"
            stroke="#FAFAFA"
            strokeWidth="1"
            fill="#FAFAFA"
            style={{ filter: "url(#dropShadowTop)" }}
          />
          <rect x="0" y="25" width="80" height="5" style={{ fill: "rgb(250, 250, 250)" }} />
          <g
            stroke="#616161"
            strokeWidth="2px"
            strokeLinecap="square"
            transform={isVisible ? "rotate(0)" : "rotate(180)"}
            transform-origin="35% 50%"
          >
            <line x1="22" y1="12" x2="28" y2="18" />
            <line x1="28" y1="18" x2="34" y2="12" />
          </g>
        </svg>
      </div>
      {isVisible && (
        <ins
          className="adsbygoogle"
          style={{ display: 'inline-block', width: '100%', height: '90px' }}
          data-ad-client={client}
          data-ad-slot={slot}
          data-ad-format="auto"
        />
      )}
    </div>
  );
};

export default FooterAd;
