// App.js

import React, { useState } from 'react';
import 'antd/dist/reset.css';
import SearchBar from './components/SearchBar';
import BackToTopButton from './components/BackToTopButton';
import './App.css';
import { ReactComponent as Logo } from './logo.svg';
import FooterAd from './components/FooterAd';

const App = () => {
  const [showLogo, setShowLogo] = useState(true);

  React.useEffect(() => {
    document.documentElement.setAttribute('data-theme', 'light');
  }, []);

  return (
    <div className="App">
      <header className={`App-header ${!showLogo ? 'no-logo' : ''}`}>
        {showLogo && <Logo className="App-logo logo-svg" />}
        <SearchBar setShowLogo={setShowLogo} />
      </header>
      <BackToTopButton />
      <FooterAd client="ca-pub-6191820453091498" slot="9563637666" />
    </div>
  );
};

export default App;